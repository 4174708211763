import jsb from 'node-jsb';

class TurboRailsBehaviour {
  constructor(domElement, options = {}) {
    this.options = { ...options };

    // DOM element
    this.domElement = typeof domElement === 'string'
      ? document.querySelector(domElement)
      : domElement;

    if (this.domElement.dataset.turboMethod || this.domElement.dataset.turboConfirm) {
      this.domElement.addEventListener('click', (event) => {
        event.preventDefault();
        this.handleLink(this.domElement);
      });
    }
  }

  async handleLink(link) {
    const confirmMessage = link.dataset.turboConfirm;
    const method = link.dataset.turboMethod?.toUpperCase();
    const url = link.href;
    const csrfToken = document.querySelector("meta[name='csrf-token']")?.content;

    if (confirmMessage && !(await showConfirmDialog(confirmMessage))) {
      return;
    }

    // if method is GET or not set, just redirect
    if (!method || method === 'GET') {
      window.location.href = url;
      return;
    }

    // create virtual form and submit it
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;

    // add method input
    const methodInput = document.createElement('input');
    methodInput.type = 'hidden';
    methodInput.name = '_method';
    methodInput.value = method;
    form.appendChild(methodInput);

    // add CSRF token if available
    if (csrfToken) {
      const csrfInput = document.createElement('input');
      csrfInput.type = 'hidden';
      csrfInput.name = 'authenticity_token';
      csrfInput.value = csrfToken;
      form.appendChild(csrfInput);
    }

    // add form to document, submit it, and remove it afterwards
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }
}

async function showConfirmDialog(message) {
  return new Promise((resolve) => {
    const confirmed = window.confirm(message);
    resolve(confirmed);
  });
}

jsb.registerHandler('TurboRails', TurboRailsBehaviour);
