import jsb from 'node-jsb'

(function () {

    'use strict';

    var LayoutSwitch = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        // DOM elements
        that.dom_element = $(dom_element);
        that.sourceElement = $('#' + that.options.source);
        that.fields = that.dom_element.find('.field');

        that.bindEvents();
        that.initialize();
    };

    LayoutSwitch.prototype.bindEvents = function() {
        var that = this;

        // quickselect
        jsb.whenFired('QuickSelect::CHANGE', function(data) {
            if (that.options.source == data.field_id)
            {
                that.hideFields();
                that.showFieldsFor(data.value);
            }
        });

        // select
        if(that.sourceElement[0].tagName == 'SELECT')
        {
            that.sourceElement.on('change', function(event){
                that.hideFields();
                that.showFieldsFor(event.target.value);
            });
        }

        // checkbox
        if(that.sourceElement[0].tagName == 'INPUT')
        {
            that.sourceElement.on('change', function(event){
                var value = this.checked.toString();

                that.hideFields();
                that.showFieldsFor(value);
            });
        }
    };

    LayoutSwitch.prototype.initialize = function ()
    {
        var that = this;
        var target = that.sourceElement.val();

        // checkbox
        if(that.sourceElement[0].tagName == 'INPUT')
        {
            target = that.sourceElement[0].checked.toString();
        }

        that.hideFields();
        that.showFieldsFor(target);
    };

    LayoutSwitch.prototype.showFieldsFor = function(target) {
        var that = this;

        if(-1 !== that.options.visible_on.split(',').indexOf(target))
        {
            that.dom_element.show();
            that.fields.find('.required').prop('required', true);
        }
    };

    LayoutSwitch.prototype.hideFields = function() {
        var that = this;

        that.dom_element.hide();
        that.fields.find('.required').prop('required', false);
    };

    jsb.registerHandler("LayoutSwitch", LayoutSwitch);

})();
