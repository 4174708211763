import jsb from 'node-jsb'

(function () {

    'use strict';

    var Footer = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        // DOM elements
        that.dom_element = $(dom_element);
        that.submit_buttons = that.dom_element.find('button');

        that.initialize();
    };

    Footer.prototype.initialize = function ()
    {
        var that = this;

        that.submit_buttons.on('click', function(event){
            var form = this.form;

            if( false === form.checkValidity() ){
                var tab_index;

                $(':input[required]').each(function(index, element){
                    if( false === element.checkValidity() )
                    {
                      var container = $(element).closest('.tab-container');
                      var container_id = $(container).attr('id');

                      tab_index = $(container).siblings('ul').find('a[href$="#' + container_id + '"]').parent(':first').index();
                    }
                });

                jsb.fireEvent('TabsBehaviour::ACTIVE', {tab_index: tab_index});
            }
        });
    };

    jsb.registerHandler("Footer", Footer);

})();
