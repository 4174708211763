import jsb from 'node-jsb'

(function () {

    var GeoCoordsFieldBehaviour = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        this.options = {};
        $.extend(this.options, options);

        that.map_options = {
            zoom: 15,
            marker_is_draggable: true,
            coords_length: 6,
            default_lat: 52.520007,
            default_lng: 13.404954
        };

        // dom elements
        that.dom_element = $(dom_element);
        that.lat_field = that.dom_element.find('.lat');
        that.lng_field = that.dom_element.find('.lng');

        that.btn = that.dom_element.find('button');
        that.map_wrapper_element = that.dom_element.find('.map_wrapper');
        that.map_element = that.dom_element.find('.map')[0];

        // helpers
        that.map_open = false;
        that.map_initialize = false;

        that.initialize();
    };

    GeoCoordsFieldBehaviour.prototype.initialize = function ()
    {
        var that = this;

        that.btn.bind('click', function(){
            if(that.map_open)
            {
                that.map_wrapper_element.hide();
            }
            else
            {
                that.map_wrapper_element.show();
            }

            if(false === that.map_initialize)
            {
                that.init_map();
            }

            that.map_open = !that.map_open;
        });
    };

    GeoCoordsFieldBehaviour.prototype.init_map = function() {
        var that = this;

        // get coords
        var lat = that.lat_field.val() || that.map_options.default_lat;
        var lng = that.lng_field.val() || that.map_options.default_lng;

        // map
        that.map = L.map(that.map_element).setView([lat, lng], 13);

        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            maxZoom: 18
        }).addTo(that.map);

        // geosearch
        var GeoSearchControl = window.GeoSearch.GeoSearchControl;
        var OpenStreetMapProvider = window.GeoSearch.OpenStreetMapProvider;
        var provider = new OpenStreetMapProvider();
        var searchControl = new GeoSearchControl({
            provider: provider,
        });
        that.map.addControl(searchControl);

        // marker
        that.marker = L.marker([lat, lng], {
            draggable: true,
        }).addTo(that.map);

        that.marker.on('dragend', function (event) {
            var position = that.marker.getLatLng();
            that.updateCoords(position)
        })

        that.map.on('geosearch/showlocation', function (payload) {
            var position = payload.marker.getLatLng()
            that.updateCoords(position)
        })

        that.map_initialize = true;
    };

    GeoCoordsFieldBehaviour.prototype.updateCoords = function(position) {
        var that = this;

        that.map.setView(position, that.map.getZoom());
        that.marker.setLatLng(position);

        that.lat_field.val(position.lat.toFixed(that.map_options.coords_length));
        that.lng_field.val(position.lng.toFixed(that.map_options.coords_length));

        that.notifyChanges();
    };

    GeoCoordsFieldBehaviour.prototype.notifyChanges = function() {
        var that = this;

        that.lat_field.addClass('update');
        that.lng_field.addClass('update');

        setTimeout(function(){
            that.lat_field.removeClass('update');
            that.lng_field.removeClass('update');
        }, 200);
    };

    jsb.registerHandler("geo_coords_field", GeoCoordsFieldBehaviour);

})();
