import jsb from 'node-jsb'

const DatepickerBehaviour = function(dom_element, options) {
  var that = this;
  that.dom_element = dom_element;
  that.options = options;

  var date_parts = that.options.value.split(', ');
  var date = date_parts[0].split('.');
  var myDate = new Date(date[2], (date[1]-1), date[0]); //new Date(1978,2,11);

  var element = $(dom_element).find('input')

  var alt_field = $('<input type="text" class="display" />')
    .prop('disabled', true);
  var button = $('<a href="#open" class="open">open</a>').click(function(event){
    event.preventDefault();
    $(element).datepicker("show");
  });

  $(element).parent().append(
    alt_field,
    button
  );

  $(element).datepicker({
    dateFormat: 'yy-mm-dd',
    showOtherMonths: true,
    selectOtherMonths: true,
    firstDay: 1,
    defaultDate: that.options.value,
  //  showOn: 'button',
  //  buttonText: 'Select...',
  //  nextText: '&rarr;',
  //  prevText: '&larr;',
    altField: $(alt_field),
    altFormat: "DD, d MM yy",
    monthNames: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag','Samstag'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
  }).datepicker( "setDate" , myDate );
};

jsb.registerHandler('datepicker', DatepickerBehaviour);
