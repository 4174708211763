import jsb from 'node-jsb'

const ReferenceField = function(dom_element, options) {
  var that = this;

  that.dom_element = dom_element;
  that.options = options;
  that.selected = [];
  that.components = {};
  that.field_id = null;

  that.sortable_target = that.options.sortable_target || that.options.target;
  that.sortable_source = that.options.sortable_source || that.options.source;

  jsb.on('ReferenceBrowserEntry::CLICK', function(data) {
    if(data.field_id == that.field_id)
    {
      that.addReference(data.reference.entry);
    }
  });

  that.init();
};

ReferenceField.prototype = {
  init: function()
  {
    this.build();
    this.renderReferences();

    var field = $('<div class="field" />');
    field.append(
      $('<label>' + this.options.label + '</label>'),
      this.reference_field
    );

    $(this.dom_element).replaceWith(field);
  },


  build: function()
  {
    var that = this;

    that.field_id = that.guid();

    that.components.references_list = $('<ul />');
    that.components.selected_input = $('<input type="hidden" name="selected" />');
    that.components.search_input = $('<input type="text" />');
    that.components.autocomplete_container = $('<div class="ac_container" />');
    that.components.autocomplete_wrapper = $('<div class="ac_wrapper" />');
    that.components.autocomplete_wrapper.append(
      that.components.search_input,
      that.components.autocomplete_container
    );

    that.components.add_button = $('<a class="btn">' + that.options.t_btn_add + '</a>').click(function(event){
      event.preventDefault();

      jsb.fireEvent('ReferenceBrowser::TOGGLE', {
        options: that.options,
        selected: that.getSelected(),
        field_id: that.field_id
      });
    });

    that.components.scroll_container = $('<div class="scroll ' + (('single' == this.options.type) ? 'single' : 'multiple') + '" />');
    that.components.scroll_container.append(
      that.components.selected_input,
      that.components.references_list
    );

    that.components.filter_container = $('<div class="filter" />');
    that.components.filter_container.append(
      that.components.autocomplete_wrapper,
      that.components.add_button
    );

    that.reference_field = $('<div class="reference_field" />');
    that.reference_field.append(
      that.components.scroll_container,
      that.components.filter_container
    );

    if(that.options.sortable)
    {
      that.components.references_list.sortable({
        axis: 'y',
        handle: '.move',
        update: function() {
          var pattern = new RegExp(that.options.sortable_target, 'g');
          var serialized_data = $(this).sortable('serialize').replace(pattern, 'sort');
          serialized_data = serialized_data + '&target=' + that.options.sortable_target;
          serialized_data = serialized_data + '&source=' + that.options.sortable_source;
          serialized_data = serialized_data + '&source_id=' + that.options.source_id;
          serialized_data = serialized_data + '&table_name=' + that.options.table_name;

          // add csrf token to headers
          var token = $('meta[name="csrf-token"]').attr('content');
          $.ajaxSetup({
            headers: { 'X-CSRF-Token': token }
          });

          $.post(that.options.sort_url, serialized_data);
        }
      });
    }
  },


  renderReferences: function()
  {
    var that = this;
    var reference = null;

    that.components.empty_element = $('<li class="empty"><input type="hidden" value="" name="' + that.getFieldName() + '" />' + that.options.t_please_choose + ' ...</li>');

    if(that.options.values.length)
    {
        var i=0;
        while(reference=that.options.values[i++])
        {
          that.addReference(reference);
        }
    }
    else
    {
        that.components.references_list.append(
          that.components.empty_element
        );
    }

    return that.references_list;
  },

  addReference: function(data)
  {
    var that = this;

    // remove placeholder (empty li)
    that.components.references_list.find('li.empty').remove();

    // rails 3.0.3 to 3.1.1 fix
    var reference = (typeof data[that.options.target] == 'undefined') ? data : data[that.options.target];

    var reference_id = parseInt(reference.id);
    var reference_title = reference.title;
    var reference_url = reference.url || null;
    var reference_description = reference.description;
    var reference_preview = reference.preview_style;

    // title
    if(that.options.title_field instanceof Array)
    {
      that.options.title_field.each(function(field){
          reference_title = reference_title + reference[field] + ' ';
      });
    }

    // description
    if(that.options.description_field instanceof Array)
    {
      that.options.description_field.each(function(field){
          reference_description = reference_description + reference[field] + ' ';
      });
    }

    // cancle if reference already selected
    if(that.setSelected(reference_id))
    {
        return false;
    }

    if (reference_url != null) {
      var locale = window.location.pathname.split('/')[1]
      var tenant = window.location.pathname.split('/')[2]
      reference_url = reference_url.replace('CURRENT_LANGUAGE', locale)
      reference_url = reference_url.replace('CURRENT_TENANT', tenant)
    }

    var title = reference_url ? '<a href="' + reference_url + '" target="_blank">' + reference_title + '</a>' : reference_title;
    var li_element = $('<li data-id="' + reference_id + '" id="' + that.options.target + '_' + reference_id + '">' + title + '</li>');

    var description = (undefined === reference_description || '' === reference_description) ? reference_id : reference_id + ' - ' + reference_description;
    li_element.append('<span>' + description + '</span>');

    // preview image
    if(undefined !== reference_preview)
    {
        var preview_image = $('<div class="preview" style="' + reference_preview +'" />');

        li_element.append(preview_image);
    }

    if(!that.options.locked)
    {
        var destroy_link = $('<a class="delete" title="' + that.options.t_btn_delete + '">' + that.options.t_btn_delete + '</a>');

        destroy_link.click(function(event){
          event.preventDefault();
          var id = parseInt($(this).parent('li').attr('data-id'));
          that.removeReference(id);
        });

        li_element.append(destroy_link);
    }

    // sortable handle
    if(that.options.sortable)
    {
      var sortable_handle = $('<a class="move icon-sort" title="' + that.options.t_btn_move + '"></a>');
      li_element.append(sortable_handle);
    }

    if(null !== reference.public)
    {
        var css_class = (reference.public) ? 'live' : 'edit';
        li_element.append($('<span class="entry-state ' + css_class + '" />'));
    }

    li_element.append($('<input type="hidden" value="' + reference_id + '" name="' + that.getFieldName() + '" />'));

    if('single' == that.options.type)
    {
        that.components.filter_container.hide();
        jsb.fireEvent('ReferenceBrowser::CLOSE', that.options);
    }

    that.components.references_list.append(li_element);

    // hide filter and refrence browser on max entries
    if('single' == that.options.type || 'multiple' == that.options.type && that.getSelected().length == that.options.max_entries)
    {
        that.components.filter_container.hide();
        jsb.fireEvent('ReferenceBrowser::CLOSE', that.options);
    }

    jsb.fireEvent('ReferenceBrowser::UPDATE_SELECTED', {
      options: that.options,
      selected: that.getSelected()
    });
  },

  removeReference: function(reference_id) {
    var that = this;

    that.components.references_list.children("li[data-id='" + reference_id + "']").remove();
    that.removeSelected(reference_id);

    if(0 === $(that.components.references_list).children().length)
    {
        that.components.references_list.append(that.components.empty_element);
        jsb.fireEvent('ReferenceBrowser::OPEN', {
          options: that.options,
          selected: that.getSelected(),
          field_id: that.field_id
        });
    }
    that.components.filter_container.show();

    jsb.fireEvent('ReferenceBrowser::UPDATE_SELECTED', {
      options: that.options,
      selected: that.getSelected(),
      field_id: that.field_id
    });
  },


  formatDatetime: function(str) {
    var pattern = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\+(\d{2}):(\d{2})$/g,
    matches = pattern.exec(str);

    if(matches)
    {
      return matches[3] + '.' + matches[2] + '.' + matches[1]; // + ' - ' + matches[4] + ':' + matches[5];
    }

    return str;
  },


  getFieldName: function() {
      var that = this;

      return that.options.field_name;
  },


  getSelected: function()
  {
    var that = this;
    var value = that.components.selected_input.val() || '[]';

    return JSON.parse(value);
  },


  removeSelected: function(id)
  {
    var that = this;

    var selected = that.getSelected();

    selected.splice($.inArray(id, selected), 1);
    that.components.selected_input.val(JSON.stringify(selected));
  },


  setSelected: function(id)
  {
    if(this.isSelected(id))
    {
        return true;
    }

    var selected = this.getSelected();
    selected.push(id);
    $(this.components.selected_input).val(JSON.stringify(selected));

    return false;
  },


  isSelected: function(id)
  {
    var selected = this.getSelected();
    var retval = (-1 != $.inArray(id, selected));
    return retval;
  },

  guid: function()
  {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
};

jsb.registerHandler('reference_field', ReferenceField);
