import jsb from 'node-jsb'

(function () {

    var PersonalDataBehaviour = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        this.options = {};

        $.extend(this.options, options);

        // dom elements
        that.dom_element = $(dom_element);
        that.field_checkbox = $(dom_element).find('input[type="checkbox"]:not(.required)');
        that.required_checkbox = $(dom_element).find('input[type="checkbox"].required');

        that.initialize();
    };

    PersonalDataBehaviour.prototype.initialize = function ()
    {
        var that = this;

        if(that.field_checkbox.prop('checked'))
        {
            that.dom_element.addClass('active');
        }

        that.field_checkbox.on('change', function(event){
            that.dom_element.toggleClass('active', $(this).prop('checked'));
        });
    };

    jsb.registerHandler("personal_data", PersonalDataBehaviour);

})();
