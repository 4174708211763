import jsb from 'node-jsb'

const ReferenceBrowser = function(dom_element) {
  var that = this;

  that.reference_browser = $(dom_element);
  that.components = {};
  that.options = {
    target: null
  };
  that.visible = false;

  jsb.on('ReferenceBrowser::TOGGLE', function(data) {
    that.init(data);
    that.updateSelected(data);
    that.close();
    that.open();
  });

  jsb.on('ReferenceBrowser::CLOSE', function(options) {
    that.close();
  });

  jsb.on('ReferenceBrowser::OPEN', function(data) {
    that.init(data);
    that.updateSelected(data);
    that.open();
  });

  jsb.on('ReferenceBrowser::UPDATE_SELECTED', function(data) {
    that.updateSelected(data);
  });
};

ReferenceBrowser.prototype = {
  init: function(data)
  {
    var options = data.options;

    if(this.options.target != options.target)
    {
      this.options = options;
      this.close();
      this.build();
      this.load(data);
    }
  },

  build: function()
  {
    var that = this;

    that.reference_browser.empty();

    var grip = $('<a class="grip"></a>').click(function(event){
      event.preventDefault();
      that.close();
    });

    that.components.spinner = $('<div class="spinner"><div/>');
    that.components.close_btn = $('<div class="close"><div/>');
    that.components.content_wrapper = $('<div class="content" />');
    that.components.entries_wrapper = $('<div class="entries" />');

    that.reference_browser.append(
      grip,
      that.components.content_wrapper
    );

    that.components.input_text = $('<input class="search-input" autocomplete="off" type="text" name="q" />');
    that.components.input_submit = $('<input class="search-submit" type="submit" value="search" />');
    that.components.search_form = $('<form class="search" />').append(
      that.components.input_text,
      that.components.input_submit
    );

    that.components.header = $('<div class="header" />').append(
      $('<h3>' + that.options.refbrowser_title + '</h3>'),
      that.components.spinner,
      that.components.close_btn
    );

    that.components.content_wrapper.append(
      that.components.header,
      that.components.entries_wrapper
    );

    that.components.close_btn.bind('click', function(){
      that.close();
    });
  },

  updateSelected: function(data)
  {
    var that = this;

    if(that.options.target == data.options.target)
    {
      $(that.components.entries_wrapper).find('.list li').removeClass('selected');
      $.each(data.selected, function(index, id){
        $(that.components.entries_wrapper).find('.list li[data-id="' + id + '"]').addClass('selected');
      });
    }
  },

  load: function(data)
  {
    var that = this;

    $(that.components.entries_wrapper).load(that.options.refbrowser_url, function(){
      jsb.applyBehaviour(window.document);
      that.updateSelected(data);
    });
  },

  open: function() {
    this.visible = true;
    this.reference_browser.addClass('visible');

    // $('body').on('click.refbrowser', function(event)
    // {
    //   this.close();
    // });
  },

  close: function() {
    this.visible = false;
    this.reference_browser.removeClass('visible');

    // $('body').off('click.refbrowser');
  },

  toggle: function() {
    if(this.visible)
    {
      this.close();
    }
    else
    {
      this.open();
    }
  }
};

jsb.registerHandler('reference_browser', ReferenceBrowser);
