import jsb from 'node-jsb'

(function () {

    'use strict';

    var InjectField = function (dom_element, options)
    {
      var that = this;
      options = options || {};

      that.options = {};
      $.extend(true, that.options, options);

      // DOM elements
      that.dom_element = $(dom_element);
      that.container = that.dom_element.parents('.sub-fields');
      that.item = that.dom_element.parents('.js-item-container');
      that.new_fields = that.container.find('.sub-fields__new');

      that.initialize();
    };

    InjectField.prototype.initialize = function ()
    {
        var that = this;

        that.updatePosition();

        that.dom_element.on('click', function(event){
          if(that.dom_element[0] === event.target)
          {
            var button = that.new_fields;
            var association = button.data('association');
            var content = button.data('content');
            var new_id = new Date().getTime();
            var regexp = new RegExp('new_' + association, "g");
            var item = content.replace(regexp, new_id);

            that.item.before(item);

            that.updatePosition();

            jsb.applyBehaviour(window.document);
          }
        });
    };

    InjectField.prototype.updatePosition = function ()
    {
      var that = this;

      var position_inputs = that.container.find('.js-position');
      position_inputs.each(function(index, input) {
        $(input).val( index + 1 );
      });
    }

    jsb.registerHandler("InjectField", InjectField);

})();
