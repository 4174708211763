import jsb from 'node-jsb'

const RadioButtonBehaviour = function(dom_element, options) {
  var radio_buttons = $(dom_element).find('input[type="radio"]');
  var btn_group = '<div class="btn-group" data-toggle="buttons-radio"></div>';

  radio_buttons.each(function(index, radio_btn){
    var label = (typeof $(radio_btn).attr('data-label') === undefined) ? $(radio_btn).prop('value') : $(radio_btn).attr('data-label');
    var btn = $('<button type="button" class="btn state-' + $(radio_btn).prop('value') + '">'+label+'</button>').click(function(event){
      event.preventDefault();
      $(btn).addClass('active').siblings('button').removeClass('active');
      $(radio_btn).prop('checked',true);
    });

    $(radio_btn).focus(function(event){
      $(btn).addClass('active').siblings('button').removeClass('active');
    });

    if($(radio_btn).prop('checked'))
      $(btn).addClass('active');

    btn_group = $(btn_group).append(btn);
  });

  // select first element if no radio box is checked / new entries
  if($(dom_element).find('input[type="radio"]:checked').length === 0)
  {
    $(dom_element).find('input[type="radio"]:first').prop("checked","checked");
    $(btn_group).find('button:first').addClass('active').siblings('button').removeClass('active');
  }

  if($(dom_element).find('.field_with_errors').length)
    $(btn_group).addClass('with_error');

  $(radio_buttons).hide();
  $(dom_element).append(btn_group);
};

jsb.registerHandler('radio_btn', RadioButtonBehaviour);
