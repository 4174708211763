import jsb from 'node-jsb'

(function () {

    'use strict';

    var MarkdownField = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        that.languages = {
          'markdown': {
            'buttons': [],
            'help': 'http://daringfireball.net/projects/markdown/'
          },
          'textile': {
            'buttons': [],
            'help': 'http://www.textism.com/tools/textile/index.php'
          }
        },

        // DOM elements
        that.dom_element = $(dom_element);

        that.initialize();
    };

    MarkdownField.prototype.initialize = function ()
    {
        var that = this;
        var configuration = {
            nameSpace:          'markdown', // Useful to prevent multi-instances CSS conflict
            onShiftEnter:       {keepDefault:false, openWith:'\n\n'},
            markupSet: [
                {name:'First Level Heading', key:"1", placeHolder:'Your title here...', closeWith:function(markItUp) { return that.markdownTitle(markItUp, '=') } },
                {name:'Second Level Heading', key:"2", placeHolder:'Your title here...', closeWith:function(markItUp) { return that.markdownTitle(markItUp, '-') } },
                {name:'Heading 3', key:"3", openWith:'### ', placeHolder:'Your title here...' },
                {name:'Heading 4', key:"4", openWith:'#### ', placeHolder:'Your title here...' },
                {name:'Heading 5', key:"5", openWith:'##### ', placeHolder:'Your title here...' },
                {name:'Heading 6', key:"6", openWith:'###### ', placeHolder:'Your title here...' },
                //{separator:'---------------' },
                {name:'Bold', key:"B", openWith:'**', closeWith:'**'},
                {name:'Italic', key:"I", openWith:'_', closeWith:'_'},
                {separator:'---------------' },
                {name:'Bulleted List', openWith:'- ' },
                {name:'Numeric List', openWith:function(markItUp) {
                    return markItUp.line+'. ';
                }},
                {separator:'---------------' },
                {name:'Picture', key:"P", replaceWith:'![[![Alternative text]!]]([![Url:!:http://]!] "[![Title]!]")'},
                //{name:'Link', key:"L", openWith:'[', closeWith:']([![Url:!:http://]!] "[![Title]!]")', placeHolder:'Your text to link here...' },
                {name:'Link', key:"L", openWith:'[', closeWith:']([![Url:!:http://]!])', placeHolder:'Your text to link here...' },
                //{separator:'---------------'},
                {name:'Quotes', openWith:'> '},
                {name:'Code Block / Code', openWith:'(!(\t|!|`)!)', closeWith:'(!(`)!)'}
            ]
        };

        that.dom_element.markItUp(configuration);
    };

    jsb.registerHandler("MarkdownField", MarkdownField);

})();



