import jsb from 'node-jsb'

(function () {

  'use strict';

  var SortableItemableContainer = function (dom_element, options)
  {
      var that = this;
      options = options || {};

      that.options = {};
      $.extend(true, that.options, options);

      $(dom_element).sortable({
        axis: 'y',
        handle: '.js-btn-move',
        cancel: '',
        update: function() {
          var positionInputs = $(this).children('.itemable__item:visible').find('.js-position');

          console.log('SortableItemableContainer: update position', positionInputs.length, positionInputs);

          positionInputs.each(function(index, item) {
            $(item).val( index + 1 );
          });
        }
      });
  };

  jsb.registerHandler("SortableItemableContainer", SortableItemableContainer);
})();
