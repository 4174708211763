import jsb from 'node-jsb'

(function () {

    var MapEdit = function (dom_element, options)
    {
        var that = this;
        that.dom_element = dom_element;
        that.options = options;

        // map
        that.default_lat = 52.520007;
        that.default_lng = 13.404954;

        that.map_options = {
          zoom: 15,
          center: new google.maps.LatLng(that.default_lat, that.default_lng),
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        // polyline
        that.polyline = null;
        that.polyline_editmode = true;
        that.polyline_options = {
            strokeColor: that.options.polyline_stroke_color, // '#000000',
            strokeOpacity: 1.0,
            strokeWeight: 2,
            editable: that.polyline_editmode
        };

        // markers
        that.markers = [];

        // listener handles
        that.listener = [];

        // dom elements
        that.map_canvas = $(that.dom_element).find('.map')[0];
        that.textarea = $(that.dom_element).find('#line_polyline_data');
        that.stroke_color_field = $(that.dom_element).find('#line_polyline_stroke_color');
        that.btn_import = $(that.dom_element).find('.btn_import');
        that.btn_clear = $(that.dom_element).find('.btn_clear');
        that.editmode_checkbox = $(that.dom_element).find('#edit_mode');
        that.searchbox_input = $(that.dom_element).find('.searchbox_input')[0];

        // polyline settings
        $("#line_polyline_stroke_color").on('change paste input', function(){
            that.setPolylineStrokeColor($(this).val());
        });

        // events
        jsb.on('TabsBehaviour::CHANGE_TAB', function() {
            that.initialize();
        });

        that.initialize();
    };


    MapEdit.prototype.initialize = function ()
    {
        var that = this;

        // init map
        that.map = new google.maps.Map( that.map_canvas, that.map_options );


        // add marker
        var station_containers = $('.station');
        $.each(station_containers, function(index, container){
            var name = $(container).find('.station_name').val();
            var lat = $(container).find('.lat').val();
            var lng = $(container).find('.lng').val();
            that.addMarker(lat, lng, name);
        });

        // init polyline
       that.loadPolyline();
       that.polyline.setMap(that.map);

        // add point to polyline
        that.listener['map_click'] = google.maps.event.addListener(that.map, 'click', function(point){
            if (that.polyline_editmode)
            {
                var path = that.polyline.getPath();
                path.push(point.latLng);
            }
        });

        // button bindings
        that.btn_import.on('click', that.loadPolyline.bind(that) );
        that.btn_clear.on('click', that.clearPolyline.bind(that) );
        that.editmode_checkbox.on('click', that.togglePolylineEditmode.bind(that) );

        that.searchbox();
    };


    MapEdit.prototype.searchbox = function() {
        var that = this;

        var searchBox = new google.maps.places.SearchBox(that.searchbox_input);

        google.maps.event.addListener(searchBox, 'places_changed', function() {
            var places = searchBox.getPlaces();
            if (places.length)
            {
                that.map.setCenter(places[0].geometry.location);
                that.map.setZoom(16);
            }

        });

        $(that.searchbox_input).keypress(function(e){
            if ( e.which == 13 ) e.preventDefault();
        });
    };


    MapEdit.prototype.togglePolylineEditmode = function() {
        var that = this;

        this.polyline_editmode = !this.polyline_editmode;
        this.polyline.setEditable(this.polyline_editmode);
    };


    MapEdit.prototype.centerMapToPolylineBounds = function() {
        var that = this;
        var path = that.polyline.getPath();
        var coordinates_count = path.getLength();
        var bounds = new google.maps.LatLngBounds();

        for (var i =0; i < path.getLength(); i++) {
           bounds.extend(path.getAt(i));
        }

        if (coordinates_count > 1) {
          that.map.fitBounds(bounds);
        }
        else if (coordinates_count == 1) {
          that.map.setCenter(bounds.getCenter());
          that.map.setZoom(that.map_options.zoom);
        }
    };


    MapEdit.prototype.loadPolyline = function() {
        var that = this;

        var path = [];
        var import_data = that.textarea.val().trim();

        if (that.polyline === null)
        {
            that.polyline = new google.maps.Polyline(that.polyline_options);
        }

        if(import_data.length > 0)
        {
            var data = JSON.parse(import_data);
            $.each(data, function(index, pos){
                if(pos['lat'] === undefined)
                {
                    // fallback for old json data
                    keys = Object.keys(pos);
                    lat = keys[0];
                    lng = keys[1];

                    path.push( new google.maps.LatLng( pos[lat], pos[lng] ) );
                }
                else
                {
                    path.push( new google.maps.LatLng( pos['lat'], pos['lng'] ) );
                }
            });

            that.polyline.setPath(path);
            that.centerMapToPolylineBounds();
        }

        that.bindPolyline();
    };


    MapEdit.prototype.savePolyline = function() {
        var that = this;

        var points = [];
        var path = that.polyline.getPath();

        for (var i =0; i < path.getLength(); i++) {
           var pos = path.getAt(i);

           points.push({
               lat: pos.lat(),
               lng: pos.lng()
           });
        }

        var data = JSON.stringify(points);
        that.textarea.val(data);
    };

    MapEdit.prototype.clearPolyline = function(event) {
        event.preventDefault();

        if (window.confirm("Linienverlauf wirklich löschen?")) {
            this.polyline.setPath([]);
            this.textarea.val('');
            this.bindPolyline();
        }
    };


    MapEdit.prototype.bindPolyline = function() {
        var that = this;

        // cleanup
        google.maps.event.removeListener(that.listener['polyline_insert_at']);
        google.maps.event.removeListener(that.listener['polyline_remove_at']);
        google.maps.event.removeListener(that.listener['polyline_set_at']);
        google.maps.event.removeListener(that.listener['polyline_rightclick']);

        // bind
        that.listener['polyline_insert_at'] = google.maps.event.addListener(that.polyline.getPath(), "insert_at", that.savePolyline.bind(that) );
        that.listener['polyline_remove_at'] = google.maps.event.addListener(that.polyline.getPath(), "remove_at", that.savePolyline.bind(that) );
        that.listener['polyline_set_at'] = google.maps.event.addListener(that.polyline.getPath(), "set_at", that.savePolyline.bind(that) );
        that.listener['polyline_rightclick'] = google.maps.event.addListener(that.polyline, 'rightclick', function(point) {
            if (point.vertex !== undefined) {
                that.polyline.getPath().removeAt(point.vertex);
            }
        });
    };


    MapEdit.prototype.setPolylineStrokeColor = function(color) {
        var that = this;

        this.polyline.setOptions({strokeColor:color});

        $.each(that.markers, function(index, marker){
            marker.setIcon({
                path: google.maps.SymbolPath.CIRCLE,
                strokeColor: color,
                scale: 4
            });
        });
    };


    MapEdit.prototype.addMarker = function(lat, lng, info) {
        var that = this;

        var point = new google.maps.LatLng(lat, lng);
        var info_window = new google.maps.InfoWindow({content: ''});
        var svg = '<?xml version="1.0"?><svg width="20px" height="20px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"><circle stroke="#000" stroke-width="1" fill="#111" cx="50" cy="50" r="35"/></svg>';

        var marker = new google.maps.Marker({
            position: point,
            clickable: true,
            icon: {
                url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg),
            },
            map: that.map,
            title: info
        });

        marker.info = info;

        google.maps.event.addListener(marker, 'click', function() {
            info_window.content = this.info;
            info_window.open(this.getMap(), this);
        });

        that.markers.push(marker);
    };

    jsb.registerHandler("MapEdit", MapEdit);

})();
