import jsb from 'node-jsb'

(function () {

    var SurveyFieldsBehaviour = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        this.options = {};

        $.extend(this.options, options);

        // dom elements
        that.dom_element = $(dom_element);
        that.dropdown = $(dom_element).find('.survey_question_type');
        that.toogle_elements = {
            answers: $(dom_element).find('.toggle-answers'),
            slider: $(dom_element).find('.toggle-slider'),
            text: $(dom_element).find('.toggle-text'),
            misc: $(dom_element).find('.toggle-misc')
        };

        that.initialize();
    };

    SurveyFieldsBehaviour.prototype.initialize = function ()
    {
        var that = this;

        that.toggle_fields(that.dropdown.val());

        that.dropdown.bind('change', function(){
            that.toggle_fields(this.value);
        });
    };

    SurveyFieldsBehaviour.prototype.toggle_fields = function (value)
    {
        var that = this;

        $.each( that.toogle_elements, function( key, element ) {
            element.hide();
        });

        if(value == 'select' || value == 'radio' || value == 'checkbox' || value == 'rating')
        {
            that.toogle_elements['answers'].show();
        }

        if(value == 'checkbox')
        {
            that.toogle_elements['misc'].show();
        }

        if(value == 'range')
        {
            that.toogle_elements['slider'].show();
        }

        if(value == 'text')
        {
            that.toogle_elements['text'].show();
        }
    };

    jsb.registerHandler("survey_fields", SurveyFieldsBehaviour);

})();
