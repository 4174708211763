import jsb from 'node-jsb'

(function () {

    'use strict';

    var SelectField = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {
            tags: false
        };
        $.extend(true, that.options, options);

        // DOM elements
        that.dom_element = $(dom_element);

        that.initialize();
    };

    SelectField.prototype.initialize = function ()
    {
        var that = this;
        var options = {
          tags: false
        };

        if(that.options.tags == 'true')
        {
            options.tags = true;
            options.tokenSeparators = [','];
        }

        // color select
        if(that.options.colors == 'true')
        {
            options.templateResult = that.templateColorsResult.bind(that);
            options.templateSelection = that.templateColorsResult.bind(that);
        }

        // icon select
        if(that.options.icons == 'true')
        {
            options.templateResult = that.templateIconsResult.bind(that);
            options.templateSelection = that.templateIconsResult.bind(that);
        }

        that.dom_element.select2(options);
        // that.dom_element.on("select2:select", function (e) { console.log("select2:select", e); });
        // that.dom_element.on("select2:close", function (e) { console.log("select2:close", e); });
        that.dom_element.on("change", function (e) {
            var selectedValue = this.selectedOptions[0].value;
            var selectedText  = this.selectedOptions[0].text;

            // console.log('selectedValue', selectedValue);
            // console.log('selectedText', selectedText);
        });
    };

    SelectField.prototype.templateColorsResult = function(state) {
        if (!state.id) { return state.text; }
        var retval = $(
          '<span><span style="position: relative; top: 4px; margin-right: 2px; border:1px solid #fff; display: inline-block; width: 15px; height: 15px; background-color: ' + state.title + '" /> ' + state.text + '</span>'
        );
        return retval;
    };

    SelectField.prototype.templateIconsResult = function(state) {
        if (!state.id) { return state.text; }
        var retval = $(
          '<span><span class="td-icon td-icon-' + state.title + '" /> ' + state.text + '</span>'
        );
        return retval;
    };

    jsb.registerHandler("SelectField", SelectField);

})();
