import jsb from 'node-jsb'

const TabsBehaviour = function(dom_element, options) {
  var that = this;
  that.dom_element = dom_element;
  that.options = options;
  that.container_class = '.tab-container';

  that.bindEvents();
  that.highlightErrors();
  that.init();
};


TabsBehaviour.prototype = {
  bindEvents: function()
  {
    var that = this;

    jsb.on('TabsBehaviour::ACTIVE', function(data) {
        $(that.dom_element).tabs( "option", "active", data['tab_index'] );
    });
  },

  init: function()
  {
    var that = this;

    $(that.dom_element).tabs({
      active: that.getTabIndex(),
      activate: function(event ,ui){
        $('select').trigger('update');
        that.setTabIndex();
      }
    });
  },

  setTabIndex: function()
  {
    var last_active_tab = {}
    var active_tab = $(this.dom_element).find('ul li.ui-state-active');
    var key = location.pathname.replace(/\//g,'');
    last_active_tab[key] = $(active_tab).index();
    $.cookie('last_active_tab', JSON.stringify(last_active_tab), { expires: 7, path: '/' });
  },

  getTabIndex: function()
  {
    var that = this;

    var active_tab = $(that.dom_element).find('ul a.active');
    var index = $(active_tab).length ? $(active_tab).parent(':first').index() : 0;

    // if(typeof $.cookie('last_active_tab') != 'undefined')
    // {
    //   var key = location.pathname.replace(/\//g,'');
    //   var last_active_tab =JSON.parse($.cookie('last_active_tab')) || {};
    //   index = last_active_tab[key] || index;
    // }

    var error_tabs = $(that.dom_element).find('ul a.error');
    var index = $(error_tabs).length ? $(error_tabs).parent(':first').index() : index;

    return index;
  },

  highlightErrors: function()
  {
    var that = this;

    $(that.container_class).each(function(index, container){
      if($(container).find('.field_with_errors').length > 0) {
        $(container).siblings('ul').find('a[href$="#' + $(container).attr('id') + '"]').addClass('error');
      }
    });
  }
};

jsb.registerHandler('Tabs', TabsBehaviour);
