import jsb from 'node-jsb'

(function () {
    'use strict';

    var VueModel = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        // DOM elements
        that.dom_element = dom_element;

        that.initialize();
    };

    VueModel.prototype.initialize = function ()
    {
        var that = this;

        var authenticity_token = document.getElementsByName('csrf-token')[0].getAttribute('content')
        Vue.http.headers.common['X-CSRF-TOKEN'] = authenticity_token

        new Vue({
          el: that.dom_element
        })
    }

    jsb.registerHandler("VueModel", VueModel)
})();
