import jsb from 'node-jsb'

const DashboardBehaviour = function(dom_element, options) {
  var modules = $(dom_element).find('.dashboard__modules');

  for (var i = 0; i < modules.length; i++) {
    if ($(modules[i]).has('li').length) {
      $(modules[i]).parents('.dashboard__section').fadeIn()
    }
  }
};

jsb.registerHandler('Dashboard', DashboardBehaviour);
