// https://github.com/josdejong/svelte-jsoneditor
// https://github.com/josdejong/jsoneditor

import jsb from 'node-jsb'
import { createJSONEditor } from 'vanilla-jsoneditor/standalone.js'

(function () {

    'use strict';

    var JsonEditorField = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        // DOM elements
        that.dom_element = $(dom_element);

        that.initialize();
    };

    JsonEditorField.prototype.initialize = function ()
    {
        var that = this;
        // hide dom element
        that.dom_element.hide()

        let content = {
          text: that.dom_element.text(),
        }

        const div = document.createElement('div')

        div.style.width = '450px'

        that.dom_element.after(div)

        const editor = createJSONEditor({
          target: div,
          props: {
            mode: 'text',
            content,
            onChange: (updatedContent, previousContent, { contentErrors, patchResult }) => {
              // content is an object { json: JSONData } | { text: string }
              // console.log('onChange', { updatedContent, previousContent, contentErrors, patchResult })
              content = updatedContent

              let value = updatedContent.text || JSON.stringify(updatedContent.json, null, 2)
              that.dom_element.text(value)
            }
          }
        })
    };

    jsb.registerHandler("JsonEditorField", JsonEditorField)
})();



