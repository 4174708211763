var sortable_table_helper = function(e, tr) {
    var $originals = tr.children();
    var $helper = tr.clone();
    $helper.children().each(function(index)
    {
      $(this).width($originals.eq(index).width());
    });
    return $helper;
};

function ready() {

  /*
   *  Sortables
   */
  $('.sortable-list').sortable({
    axis: 'y',
    handle: '.handle',
    update: function() {
      var token = $('meta[name="csrf-token"]').attr('content');

      // add csrf token to headers
      $.ajaxSetup({
        headers: { 'X-CSRF-Token': token }
      });

      $.post($(this).data('update-url'), $(this).sortable('serialize'))
    }
  });

  $('.sortable-fields').sortable({
    axis: 'y',
    handle: '.handle',
    update: function() {
      $(this).children('.js-item-container:visible').find('.js-position').each(function(index, item) {
        $(item).val( index + 1 );
      });
    }
  });

  $('.itemable').sortable({
    axis: 'y',
    handle: '.handle',
    update: function() {
      var sections = $(this).children('.itemable__section:not(.itemable__section--add)');

      sections.each(function(index, item) {
        $(item).find('.js-section').val( index + 1 )
      });
    }
  });

  $(".sortable tbody").sortable({
    helper: sortable_table_helper
  }).disableSelection();

  /*
   * Tool Tips
   */

  $('.tooltip').tooltipster({
    delay: 500
  });

  /*
   * Select all Checkboxes
   */

  var innerText = $('.checkall').text()
  $('.checkall').replaceWith('<div class="checkall"><input type="checkbox" id="select_all"><label for="select_all">' + innerText + '</label></div>');
  $('#select_all').on('change', function (event) {
    $(this).parents('.tab-container').find('.site-checkboxes input').prop('checked', $(this).is(':checked'));
  });

  /*
   * Notifications
   */

  $('.flash').delay(1500).fadeOut(400);


  /*
   * IE Notifications
   */

  if ( $.browser.msie ) {
    $('.main-header .container').prepend('<div class="flash info">Ihr Internet Explorer ' + $.browser.version + ' unterstützt nicht alle Funktionen.<br />Die weitere Nutzung kann zu Fehlfunktionen, Fehlermeldungen oder Datenverlust führen!</div>');
    $('.flash').delay(5000).fadeOut(400);
  }

  /*
   * Sites Dropdown
   */

  $('.sites-dropdown__trigger').on('click', function(event){
    event.preventDefault();

    if( $('.sites').css('display') == 'block' )
    {
      $('.sites').hide();
      $('.content').show();
      $('.navigation').show();
      $(this).addClass('open').removeClass('close');
    }
    else
    {
      $('.sites').show();
      $('.content').hide();
      $('.navigation').hide();
      $(this).addClass('close').removeClass('open');
    }
  });

  if(window.location.pathname == '/de/sites')
  {
    $('.sites').show();
    $('.content').hide();
    $('.navigation').hide();
  }

  //jsb.applyBehaviour(window.document);
}

$(function() {
  ready()
})
$(document).on('page:load', ready)
