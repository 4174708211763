import jsb from 'node-jsb'

(function () {

    'use strict';

    var HomepageModules = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        that.modules = JSON.parse(that.options.translations)

        // DOM elements
        that.dom_element = $(dom_element);
        that.input_element = that.dom_element.find('input');
        that.input_wrapper = that.dom_element.find('.text-input-wrapper');

        that.initialize();
    };

    HomepageModules.prototype.initialize = function ()
    {
        var that = this;

        var input_data = that.input_element.val().split(',');
        var wrapper = $('<div class="homepage-modules" />');
        that.list = $('<ul>');

        // render active
        $.each(input_data, function(index, key){
            var value = that.modules[key];
            if(value)
            {
                that.render(key, value, true);
            }
        });

        // render missing
        $.each(that.modules, function(key, value) {
            if($.inArray(key, input_data) == -1)
            {
                that.render(key, value, false);
            }
        });

        // update input
        that.updateInput();

        // hide input
        that.input_element.hide();

        wrapper.append(that.list);
        that.input_wrapper.append(wrapper);

        // sortable
        that.list.sortable({
            axis: 'y',
            handle: '.handle',
            update: function(event, ui) {
                that.updateInput();
            }
        });
    };

    HomepageModules.prototype.render = function(key, value, active)
    {
      var that = this;
      var toggle_active = $('<span class="toggle-active"><i class="icon-preview"></i></span>');

      toggle_active.click(function(event){
          $(this).parent('li').toggleClass('active');
          that.updateInput();
      });

      var li = $('<li data-module="' + key + '">' + value + '</li>');
      li.prepend(toggle_active);
      li.prepend('<span class="handle"><i class="icon-sort"></i></span>');
      if(active)
      {
          li.addClass('active');
      }

      that.list.append(li);
    };

    HomepageModules.prototype.updateInput = function() {
        var that = this;
        var order_str = $.map(that.list.find('li.active'), function (item) { return $(item).attr('data-module'); });
        that.input_element.val(order_str);
    };

    jsb.registerHandler("HomepageModules", HomepageModules);

})();
