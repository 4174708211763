import jsb from 'node-jsb'

const ReferenceBrowserEntryBehaviour = function(dom_element, options) {
  var that = this;
  that.dom_element = dom_element;
  that.options = options;
  that.field_id = null;

  jsb.whenFired('ReferenceBrowser::OPEN', function(data) {
    that.field_id = data.field_id;
  });

  jsb.whenFired('ReferenceBrowser::TOGGLE', function(data) {
    that.field_id = data.field_id;
  });


  that.init();
};

ReferenceBrowserEntryBehaviour.prototype = {
  init: function()
  {
    var that = this;

    $(that.dom_element).click(function(event){
      event.preventDefault();
      jsb.fireEvent('ReferenceBrowserEntry::CLICK', {
        reference: that.options,
        field_id: that.field_id
      });
    });
  }
};

jsb.registerHandler('reference_browser_entry', ReferenceBrowserEntryBehaviour);
