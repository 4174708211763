import jsb from 'node-jsb'

(function () {

  'use strict';

  var ItemableListBehaviour = function (dom_element, options)
  {
      var that = this;
      options = options || {};

      that.options = {};
      $.extend(true, that.options, options);

      that.dom_element = $(dom_element);
      that.buttons = that.dom_element.find('button');

      that.buttons.addClass('jsb_ jsb_AddItemable');

      jsb.applyBehaviour(that.dom_element[0]);
  };

  jsb.registerHandler("ItemableListBehaviour", ItemableListBehaviour);
})();
