import jsb from 'node-jsb'

(function() {

    "use strict";

    var Toggle = function(dom_element, options)
    {
        var that = this;
        var options = options || {};

        // Options
        that.options = {
            id: options.id || dom_element,
            toggle_class: options.toggle_class || 'toggled',
            trigger_class: options.trigger_class || 'js-toggle-trigger',
            container_class: options.container_class || 'js-toggle-container'
        };

        $.extend(that.options, options);


        // DOM Elements
        that.html_element = $('html');
        that.dom_element = $(dom_element);
        that.trigger = that.dom_element.find('.' + that.options.trigger_class);
        that.container = that.dom_element.find('.' + that.options.container_class);

        // Helpers
        that.is_open = false;
        that.click_support = false;
        that.default_trigger_text = that.trigger.html();


        // set itself to toggle container
        if (that.container.length < 1)
        {
            that.container = that.dom_element;
        }

        // set itself to toggle button
        if (that.trigger.length < 1)
        {
            that.trigger = that.dom_element;
        }

        that.trigger.on('click', function(event)
        {
            var target = event.target;

            event.stopPropagation();
            event.preventDefault();

            that.html_element.on('click.toggle', function(event)
            {
                if ( (that.container[0] != target) && ($.contains(that.container[0], target) === false))
                {
                    that.hide();
                    that.html_element.off('click.toggle');
                }
            });

            if (!that.is_open)
            {
                that.show();
            }
            else
            {
                that.hide();
            }

            jsb.fireEvent('Toggle::TOGGLED', {'id': that.options.id});
        });
    };

    Toggle.prototype.hide = function()
    {
        var that = this;

        that.is_open = false;
        that.container.removeClass( that.options.toggle_class );
        that.trigger.removeClass( that.options.toggle_class );
    };

    Toggle.prototype.show = function()
    {
        var that = this;

        that.is_open = true;
        that.container.addClass( that.options.toggle_class );
        that.trigger.addClass( that.options.toggle_class );
    };

    jsb.registerHandler('Toggle', Toggle);

})();
