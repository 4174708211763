Vue.component('quick-select', {
  template: '<select :name="name"><slot></slot></select>',
  props: {
    name: {
      type: String
    },
    breakout: {
      type: String
    },
    value: {
      type: String
    }
  },
  mounted: function () {
     var that = this;

     var options = {
        activeButtonClass: 'qs-btn-primary active',
        buttonClass: 'qs-btn qs-btn-default',
        selectDefaultText: '...',
        wrapperClass: 'qs-btn-group'
      }

      if(that.breakout)
      {
        options.breakOutValues = that.breakout.split(',');
      }
      else
      {
        options.breakOutAll = true;
      }

     $(this.$el)
       .quickselect( options )
       .val(this.value)
       .trigger('change')
       .on('change', function () {
          that.value = this.value;
          that.$emit('input', this.value);
       })
   },
   watch: {
     value: function (value) {
        $(this.$el).val(value).trigger('change');
     }
   },
   destroyed: function () {
     $(this.$el).off().quickselect('destroy')
   }
});
