import jsb from 'node-jsb'

const LayoutSwitchBehaviour = function(dom_element, options) {
  var that = this;
  that.dom_element = dom_element;
  that.options = options;

  that.tabs_container = $(that.dom_element).parents('.sub-field-tabs');

  $(that.dom_element).find('input').hide();

  var current_layout = $(that.dom_element).find('input').val();

  var li_element = $(that.dom_element).find('ul').find('li[data-layout="' + current_layout + '"]');
  that.activateTab(li_element);

  $(that.dom_element).find('ul li').bind('click', function(event){
    event.preventDefault();
    that.activateTab(this);
  });
};


LayoutSwitchBehaviour.prototype = {
  activateTab: function(li_element)
  {
    var that = this;
    var hidden_tabs = $(li_element).attr('data-hidden-tabs').split(',');
    var layout = $(li_element).attr('data-layout');

    $(that.tabs_container).find('.icon').removeClass().addClass('icon page-item-' + layout.replace('_', '-'))

    $(that.tabs_container).find('.ui-tabs-nav').children('li').show();
    $.each(hidden_tabs, function(index, tab){
      if('' != tab)
      {
        $(that.tabs_container).find('.ui-tabs-nav li.' + tab).hide();
      }
    });

    $(that.dom_element).find('input').val(layout);

    $(li_element).addClass('active').siblings().removeClass('active');
  }
}

jsb.registerHandler('layout_switch', LayoutSwitchBehaviour);
