import jsb from 'node-jsb'

(function () {

    'use strict';

    var AddItemable = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        // DOM elements
        that.dom_element = $(dom_element);
        that.target_contrainer = that.dom_element.parents('ul').siblings('.itemable__container');

        that.initialize();
    };

    AddItemable.prototype.initialize = function ()
    {
        var that = this;

        that.dom_element.on('click', function(event){
          event.preventDefault();

          var association = that.dom_element.data('association');
          var content = that.dom_element.data('content');
          var new_id = new Date().getTime();
          var regexp = new RegExp('new_' + association, "g");
          var item = content.replace(regexp, new_id);

          that.target_contrainer.append(item);

          // update position
          var position_inputs = that.target_contrainer.find('.js-position');
          position_inputs.each(function(index, input) {
            $(input).val( index + 1 );
          });

          jsb.fireEvent('ItemableSection::UPDATE_SECTION_INPUTS');

          jsb.applyBehaviour(window.document);
        });
    };

    jsb.registerHandler("AddItemable", AddItemable);

})();
