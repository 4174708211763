Vue.component('select2', {
  template: '<select :name="name"><slot></slot></select>',
  props: {
    value: {
      type: String
    },
    name: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      options: {
        tags: true
      }
    }
  },
  methods: {
    setPlaceholder: function() {
      if(this.placeholder.length != 0)
      {
        this.options['placeholder'] = this.placeholder;
        this.options['allowClear'] = true;
      }
    }
  },
  mounted: function () {
     var that = this;

     this.setPlaceholder();

     $(this.$el)
       .select2( that.options )
       .val(this.value)
       .trigger('change')
       .on('change', function () {
         that.$emit('input', this.value)
       })
   },
   watch: {
     value: function (value) {
       $(this.$el).val(value).trigger('change');
     },
     options: function (options) {
       $(this.$el).select2({ data: options })
     }
   },
   destroyed: function () {
     $(this.$el).off().select2('destroy')
   }
});
