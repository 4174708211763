import jsb from 'node-jsb'

const ZhvBehaviour = function(dom_element, options) {
  var that = this;
  options = options || {};

  that.options = {};
  $.extend(true, that.options, options);

  // DOM elements
  that.dom_element = $(dom_element);
  that.fieldWrapper = that.dom_element.closest('.station')[0];
  that.input_dhid = that.fieldWrapper.getElementsByClassName('dhid')[0];
  that.input_station_latitude = that.fieldWrapper.getElementsByClassName('lat')[0];
  that.input_station_longitude = that.fieldWrapper.getElementsByClassName('lng')[0];

  that.initialize();
}

ZhvBehaviour.prototype.initialize = function() {
  var that = this;

  that.dom_element.autocomplete({
    source: function(request, response) {
      $.ajax({
          url: that.options.url,
          method: "GET",
          data: { term: request.term },
          success: function(data) {
              response($.map(data, function(item) {
                  return {
                      label: item.name,
                      value: item.name,
                      dhid: item.dhid,
                      latitude: item.latitude,
                      longitude: item.longitude
                  };
              }));
          },
          error: function(xhr, status, error) {
              console.error("Fehler beim Abrufen der Daten:", status, error);
          }
      });
    },
    minLength: 2,
    select: function(event, ui) {
      if (that.input_dhid) {
        if (that.input_dhid.value == '') {
          that.applyData(ui.item);
        } else {
          if (confirm('Sollen auch die DHID und Koordination übernommen werden?')) {
            that.applyData(ui.item);
          }
        }
      }
    }
  })
}

ZhvBehaviour.prototype.applyData = function(data) {
  var that = this;

  if (that.input_dhid) {
    that.input_dhid.value = data.dhid;
  }

  if (that.input_station_latitude) {
    that.input_station_latitude.value = data.latitude && data.latitude.replace(',', '.');
  }

  if (that.input_station_longitude) {
    that.input_station_longitude.value = data.latitude && data.longitude.replace(',', '.');
  }
}


jsb.registerHandler('Zhv', ZhvBehaviour);
