import jsb from 'node-jsb'

(function () {

    'use strict';

    var ItemableSection = function (dom_element, options)
    {
      var that = this;
      options = options || {};

      that.options = {};
      $.extend(true, that.options, options);

      // DOM elements
      that.dom_element = $(dom_element);

      that.section_title = that.dom_element.find('.itemable__section-title');
      that.section_btn_add = that.dom_element.find('.itemable__section-btn-add');
      that.itemable__list = that.dom_element.find('.itemable__list');

      that.section_btn_add.on('click', function() {
        that.appendSection();
      })

      jsb.on('ItemableSection::UPDATE_SECTION_INPUTS', function(data) {
        that.updateSectionIds();
    });
    };

    ItemableSection.prototype.appendSection = function ()
    {
      var that = this;

      that.new_section = that.dom_element.clone();
      that.new_section.removeClass('itemable__section--add');
      that.new_section.find('.itemable__section-btn-add').remove();
      that.new_section.find('.itemable__list').addClass('jsb_ jsb_ItemableListBehaviour');
      that.new_section.find('.itemable__container').addClass('jsb_ jsb_SortableItemableContainer');

      that.new_section.insertBefore(that.dom_element);
      that.updateSectionIds();

      jsb.applyBehaviour(window.document);
    }

    ItemableSection.prototype.updateSectionIds = function ()
    {
      var that = this;

      var sections = that.dom_element.parents().find('.itemable__section');

      console.log('ItemableSection: updateSectionIds', sections.length, sections);

      sections.each(function(index, section) {
        $(section).find('.js-section').val( index + 1 );
      })
    }

    jsb.registerHandler("ItemableSection", ItemableSection);

})();
