import jsb from 'node-jsb'

const UploadFieldBehaviour = function(dom_element, options) {
  var that = this;

  that.dom_element = $(dom_element);
  that.options = options;

  that.extension_white_list = options.extension_white_list;
  that.object_name = options.object_name;
  that.field_name = options.field_name;
  that.file_url = options.file_url;
  that.file_cache = options.file_cache;
  that.file_preview = options.file_preview || null;
  that.file_name = options.file_name || null;
  that.file_size = options.file_size || null;

  that.render();
};

UploadFieldBehaviour.prototype = {
  render: function()
  {
    var that = this;

    // base
    var upload_field = $('<div class="upload_field" />');
    var label = $('<label />')
      .attr('for', that.getFieldname())
      .text(that.options.label);

    // details
    if(that.file_name)
    {
      var wrapper_details = $('<div class="wrapper-details" />');

      if(that.file_preview)
      {
        var timestamp = Math.round(+new Date()/1000);
        wrapper_details.append($('<img src="' + that.file_preview + '?' + timestamp + '" alt="" />'));
      }
      wrapper_details.append(
        $('<span class="filename"><span>' + that.options.t_filename + '</span>: <a target="_blank" href="' + that.file_url + '">' + that.file_name + '</a></span>'),
        $('<span class="filesize"><span>' + that.options.t_filesize + '</span>: ' + that.file_size + '</span>'),
        $('<span class="delete"><label><input type="checkbox" name="' + that.getFieldname('remove') +'" /> ' + that.options.t_btn_delete + '</label></span>')
      );

      upload_field.append(wrapper_details);
    }

    // upload field
    var file_types = (null != that.extension_white_list) ? '<span class="file-types">( ' + that.extension_white_list + ' )</span>' : '';
    var fakepath_blank = that.options.t_no_file_selected + ' ' + file_types + ' ...';
    var fakepath = $('<span class="fakepath">' + fakepath_blank +'</span>');
    var button = $('<a class="btn">' + that.options.t_btn_select + '</a>');
    var file_input = $('<input />')
      .attr('type', 'file')
      .attr('name', that.getFieldname());
    var file_cache = $('<input />')
      .attr('type', 'hidden')
      .attr('name', that.getFieldname('cache'))
      .val(that.file_cache);
    var file_current_url = $('<input />')
      .attr('type', 'hidden')
      .attr('name', that.getFieldname('current_url'))
      .val(that.file_url);
    var wrapper_upload = $('<div class="wrapper-upload" />').append(
      button,
      fakepath,
      file_input,
      file_cache,
    );

    if(!that.file_name)
    {
      upload_field.append(wrapper_upload);
    } else {
      upload_field.append(file_current_url);
    }

    file_input.bind('change', function(){
      var filename = $(this).val().replace('C:\\fakepath\\', '');
      fakepath.text(filename);
      if(false == that.validateFile(filename))
      {
        $(file_input).val('');
        fakepath.html(fakepath_blank);
      }
    });

    // required
    if(that.options.required) {
      label.addClass('required');
      upload_field.addClass('required');
    }

    // build
    that.dom_element.append(
      label,
      upload_field
    );
  },

  validateFile: function(filename)
  {
    var extension = filename.split('.').pop().toLowerCase();

    if(this.extension_white_list == null)
    {
      return true;
    }

    if(-1 == $.inArray(extension, this.extension_white_list))
    {
      alert(this.options.t_allowed_files);
      return false;
    }
    return true;
  },

  getFieldname: function(type)
  {
        var pattern = (undefined == type) ? 'OBJECT[FIELD]': 'OBJECT[remote_FIELD_url]';
        var pattern = ('cache' == type) ? 'OBJECT[FIELD_cache]' : pattern;
        var pattern = ('current_url' == type) ? 'OBJECT[FIELD_current_url]' : pattern;
        var pattern = ('remove' == type) ? 'OBJECT[remove_FIELD]' : pattern;
        var field_name = pattern.replace(/OBJECT/g, this.object_name);
        field_name = field_name.replace(/FIELD/g, this.field_name);

        return field_name;
  }
}

jsb.registerHandler('upload_field', UploadFieldBehaviour);
