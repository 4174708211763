import jsb from 'node-jsb'

(function () {

    'use strict';

    var AddFields = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        // DOM elements
        that.dom_element = $(dom_element);

        that.sub_fields = that.dom_element.closest('.sub-fields');
        that.sortable_fields = that.sub_fields.find('> .sortable-fields');

        that.initialize();
    };

    AddFields.prototype.initialize = function ()
    {
        var that = this;

        that.dom_element.on('click', function(event){
          event.preventDefault();

          var association = that.dom_element.data('association');
          var content = that.dom_element.data('content');
          var new_id = new Date().getTime();
          var regexp = new RegExp("new_" + association, "g");
          var item = $(content.replace(regexp, new_id));

          // append fields
          that.sortable_fields.append(item);

          // update position fields
          that.sortable_fields.children('.fields-container:visible').find('.js-position').each(function(index, entry) {
            $(entry).val( index + 1 );
          });

          // set focus
          item.find('input[type=text],textarea,select').filter(':visible:first').focus();

          jsb.applyBehaviour(window.document);

        });
    };

    jsb.registerHandler("AddFields", AddFields);

})();
