import jsb from 'node-jsb'

(function () {

    var ColorPickerBehaviour = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {
            animationSpeed: 50,
            animationEasing: 'swing',
            change: that.changeColor(),
            changeDelay: 0,
            control: 'hue',
            dataUris: true,
            defaultValue: '#000000',
            hide: null,
            hideSpeed: 100,
            inline: false,
            letterCase: 'lowercase',
            opacity: false,
            position: 'bottom left',
            show: null,
            showSpeed: 100,
            theme: 'default'
        };

        $.extend(that.options, options);

        // extend minicolors default options
        $.minicolors.defaults = $.extend($.minicolors.defaults, that.options);

        // dom elements
        that.dom_element = $(dom_element);
        that.input_field = $(dom_element).find('input[type="text"]');

        that.initialize();
    };

    ColorPickerBehaviour.prototype.initialize = function ()
    {
        var that = this;

        that.input_field.minicolors();
    };

    ColorPickerBehaviour.prototype.changeColor = function (hex, opacity)
    {

    };

    jsb.registerHandler("color_picker", ColorPickerBehaviour);

})();
