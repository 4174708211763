import jsb from 'node-jsb'

const PaginateBehaviour = function(dom_element, options) {
  var that = this;
  that.dom_element = dom_element;
  that.options = options;

  $(dom_element).pajinate({
    items_per_page : 15,
    num_page_links_to_display: 10,
    abort_on_small_lists: true,
    show_first_last: true,
    item_container_id : '.list',
    nav_panel_id : '.pagination',
    nav_label_first : '<<',
    nav_label_last : '>>',
    nav_label_prev : '<',
    nav_label_next : '>'
  });
};

jsb.registerHandler('paginate', PaginateBehaviour);
