import jsb from 'node-jsb'

(function () {

    'use strict';

    var InjectItemable = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        // DOM elements
        that.dom_element = $(dom_element);
        that.itemable = $('.itemable');
        that.itemable_section = that.dom_element.parents('.itemable__section');
        that.itemable_item = that.dom_element.parents('.itemable__item');
        that.itemable_list = that.itemable_section.find('.itemable__list').first().clone();

        that.initialize();
    };

    InjectItemable.prototype.initialize = function ()
    {
        var that = this;

        that.dom_element.append(that.itemable_list);
        var buttons = that.dom_element.find('button');
        buttons.on('click', function(event){
          event.preventDefault();

          var button = $(this);
          var association = button.data('association');
          var content = button.data('content');
          var new_id = new Date().getTime();
          var regexp = new RegExp('new_' + association, "g");
          var item = content.replace(regexp, new_id);

          that.itemable_item.before(item);
          jsb.applyBehaviour(window.document);

          that.dom_element.removeClass('itemable__add--active');

          // update position
          var position_inputs = that.dom_element.parents('.itemable__container').find('.js-position');
          position_inputs.each(function(index, input) {
            $(input).val( index + 1 );
          });
        });

        that.dom_element.on('click', function(event){
          if(that.dom_element[0] === event.target)
          {
            that.dom_element.addClass('itemable__add--active');
            jsb.applyBehaviour(window.document);
          }
        });
    };

    jsb.registerHandler("InjectItemable", InjectItemable);

})();
