import jsb from 'node-jsb'

Vue.component('dispatcher', {
  template: '#dispatcher-template',
  props: {
    selected: {
      type: String,
    },
    populate_data: {
      type: String
    },
    locale: {
      type: String
    },
    placeholder: {
      type: String
    }
  },
  data: function() {
    return {
      user_input: {
        short_description: '',
        long_description: ''
      },
      dispatcher_prototype: '',
      input: {
        construction_work: 'false',
        reason: '',
        explanation: '',
        replacement_transport: '',
        location_1: '',
        location_2: '',
        location_3: '',
        location_4: '',
        short_description: '',
        long_description: ''
      }
    }
  },
  mounted: function() {
    this.populateInputFields();

    jsb.applyBehaviour(this.$el);
  },
  methods: {
    getPlaceholder: function (key) {
      var data = JSON.parse(this.placeholder);
      return '____(' + data[key] + ')____';
    },
    renderPlaceholder: function (input, fallback) {
      return (input.length == 0) ? fallback : input;
    },
    userChange: function(event) {
      this.dispatcher_prototype = 'free';

      if(event.target.name == 'interruption[short_description]')
      {
        this.input.short_description = event.target.value;
        this.input.long_description = this.input.long_description;
      }
      else
      {
       this.input.long_description = event.target.value;
       this.input.short_description = this.input.short_description;
      }
    },
    populateInputFields: function() {
      var that = this;

      this.dispatcher_prototype = (this.selected == '') ? 'delay' : this.selected;

      var data = JSON.parse(this.populate_data);
      $.each(data, function(key, value) {
        if(value != null)
        {
          that.input[key] = value;
        }
      })
    }
  },
  computed: {
    generated_title: function() {
      if (this.dispatcher_prototype == 'closure') { return this.title_closure; }
      if (this.dispatcher_prototype == 'delay') { return this.title_delay; }
      if (this.user_input.short_description.length > 0) { return this.user_input.short_description; }

      return this.input.short_description;
    },
    generated_text: function() {
      if (this.dispatcher_prototype == 'closure') { return this.text_closure; }
      if (this.dispatcher_prototype == 'delay') { return this.text_delay; }
      if (this.user_input.long_description.length > 0) { return this.user_input.long_description; }

      return this.input.long_description;
    },
    title_delay: function() {
      switch (this.locale) {
        case 'en':
          return "title_delay EN"
        case 'cz':
          return "title_delay CZ"
        default:
          return "Verspätung aufgrund " + this.renderPlaceholder(this.input.reason, this.getPlaceholder('reason'));
      }
    },
    title_closure: function() {
      switch (this.locale) {
        case 'en':
          return "title_closure EN"
        case 'cz':
        return "title_closure CZ"
        default:
          return "Streckensperrung zwischen " + this.renderPlaceholder(this.input.location_1, this.getPlaceholder('location_1')) + " und " + this.renderPlaceholder(this.input.location_2, this.getPlaceholder('location_2')) + " aufgrund " + this.renderPlaceholder(this.input.reason, this.getPlaceholder('reason'));
      }
    },
    text_delay: function() {
      switch (this.locale) {
        case 'en':
          return "text_delay EN"
        case 'cz':
        return "text_delay CZ"
        default:
          return "Sehr geehrte Fahrgäste,\n\raufgrund " + this.renderPlaceholder(this.input.reason, this.getPlaceholder('reason')) + " kommt es derzeit zu " + this.renderPlaceholder(this.input.explanation, this.getPlaceholder('explanation')) + ".\n\rWir halten Sie an dieser Stelle auf dem Laufenden."
      }
    },
    text_closure: function() {
      var text_de =  "Sehr geehrte Fahrgäste,\n\r" +
                    "aufgrund " + this.renderPlaceholder(this.input.reason, this.getPlaceholder('reason')) + " können aktuell die Züge auf dem Streckenabschnitt zwischen " + this.renderPlaceholder(this.input.location_1, this.getPlaceholder('location_1')) + " und " + this.renderPlaceholder(this.input.location_2, this.getPlaceholder('location_2')) + " nicht verkehren.\n\r" +
                    this.renderPlaceholder(this.input.replacement_transport, this.getPlaceholder('replacement_transport')) + "\n\r";

      if( this.input.location_3.length > 0 || this.input.location_4.length > 0 )
      {
          text_de += "Zwischen " + this.renderPlaceholder(this.input.location_3, this.getPlaceholder('location_3')) + " und " + this.renderPlaceholder(this.input.location_4, this.getPlaceholder('location_4')) + " fahren die Züge planmäßig.\n\r";
      }

      text_de += "Wir halten Sie an dieser Stelle auf dem Laufenden.";

      var text_en = 'text_closure EN'
      var text_cz = 'text_closure CZ'

      switch (this.locale) {
        case 'en':
          return text_en;
        case 'cz':
          return text_cz;
        default:
          return text_de;
      }
    }
  }
});



