import jsb from 'node-jsb'

(function () {

    'use strict';

    var QuickSelect = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        // DOM elements
        that.dom_element = $(dom_element);
        that.select_element = that.dom_element.find('select');

        that.initialize();
    };

    QuickSelect.prototype.initialize = function ()
    {
        var that = this;
        var options = {
              activeButtonClass: 'qs-btn-primary active',
              buttonClass: 'qs-btn qs-btn-default',
              selectDefaultText: '...',
              wrapperClass: 'qs-btn-group'
        };

        if(that.options.breakout)
        {
          options.breakOutValues = that.options.breakout.split(',');
        }
        else
        {
          options.breakOutAll = true;
        }

        that.select_element.quickselect(options);

        that.select_element.on('change', function(){

            jsb.fireEvent('QuickSelect::CHANGE', {
                field_id: this.id,
                value: $(this).val()
            });
        });
    };

    jsb.registerHandler("QuickSelect", QuickSelect);
})();
