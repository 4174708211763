import jsb from 'node-jsb'

(function () {

    'use strict';

    var RemoveFields = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        // DOM elements
        that.dom_element = $(dom_element);
        that.itemable_item = that.dom_element.closest('.js-item-container');

        that.initialize();
    };

    RemoveFields.prototype.initialize = function ()
    {
        var that = this;

        that.dom_element.on('click', function(event){
            event.preventDefault();

            if(confirm('Wirklich löschen?'))
            {
              that.itemable_item.find('.required').prop('required', false);
              that.itemable_item.find('.js-destroy').val('1');
              that.itemable_item.fadeOut();
            }
        });
    };

    jsb.registerHandler("RemoveFields", RemoveFields);

})();
