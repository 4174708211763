import jsb from 'node-jsb'

const MarkupFieldBehaviour = function(dom_element, options) {
  this.dom_element = $(dom_element);
  this.options = options;
  this.languages = {
    'markdown': {
      'buttons': [],
      'help': 'http://daringfireball.net/projects/markdown/'
    },
    'textile': {
      'buttons': [],
      'help': 'http://www.textism.com/tools/textile/index.php'
    }
  },

  this.init();
}

MarkupFieldBehaviour.prototype = {
  init: function()
  {
    var that = this;
    var element = $('<textarea />');
    element.attr('name', this.getFieldname())
    element.val(this.options.value);

    var icon_help = $('<span />')
        .text(that.options.language)
        .addClass('language')
        .click(function(){
          window.open(that.languages[that.options.language]['help']);
        });

    var icon_preview = $('<span />')
        .text('vorschau')
        .addClass('preview')
        .attr('title', 'Vorschau')
        .click(function(){

          jQuery.ajax({
            type: "POST",
            url: '/markdown/preview',
            data: {
              'text': element.val()
            },
            success: function(data, textStatus, jqXHR) {
              jQuery.colorbox({
                html: data,
                transition: "none",
                fixed: true
              });
            },
            dataType: 'html'
          });

        });

    var label = $('<label />')
        .attr('for', that.getFieldname())
        .text(that.options.label);

    if(that.options.required) {
      label.addClass('required')
    }

    this.dom_element.append(
      label,
      element
    );

    config = this.getConfiguration(this.options.language)

    // add preview link
    element.markItUp(config)
      //.parent().prepend(icon_preview);
  },


  getFieldname: function()
  {
    var field_name = 'OBJECT[FIELD]'.replace(/OBJECT/g, this.options.object_name);
    field_name = field_name.replace(/FIELD/g, this.options.field_name);

    return field_name;
  },


  markdownTitle: function(markItUp, char) {
    heading = '';
    n = $.trim(markItUp.selection||markItUp.placeHolder).length;
    for(i = 0; i < n; i++) {
        heading += char;
    }
    return '\n'+heading+'\n';
  },


  getConfiguration: function(language)
  {
    var that = this;
    var configuration = {};
    /*
     * Markdown
     */
    configuration['markdown'] = {
        nameSpace:          'markdown', // Useful to prevent multi-instances CSS conflict
        onShiftEnter:       {keepDefault:false, openWith:'\n\n'},
        markupSet: [
            {name:'First Level Heading', key:"1", placeHolder:'Your title here...', closeWith:function(markItUp) { return that.markdownTitle(markItUp, '=') } },
            {name:'Second Level Heading', key:"2", placeHolder:'Your title here...', closeWith:function(markItUp) { return that.markdownTitle(markItUp, '-') } },
            {name:'Heading 3', key:"3", openWith:'### ', placeHolder:'Your title here...' },
            {name:'Heading 4', key:"4", openWith:'#### ', placeHolder:'Your title here...' },
            {name:'Heading 5', key:"5", openWith:'##### ', placeHolder:'Your title here...' },
            {name:'Heading 6', key:"6", openWith:'###### ', placeHolder:'Your title here...' },
            //{separator:'---------------' },
            {name:'Bold', key:"B", openWith:'**', closeWith:'**'},
            {name:'Italic', key:"I", openWith:'_', closeWith:'_'},
            {separator:'---------------' },
            {name:'Bulleted List', openWith:'- ' },
            {name:'Numeric List', openWith:function(markItUp) {
                return markItUp.line+'. ';
            }},
            {separator:'---------------' },
            {name:'Picture', key:"P", replaceWith:'![[![Alternative text]!]]([![Url:!:http://]!] "[![Title]!]")'},
            //{name:'Link', key:"L", openWith:'[', closeWith:']([![Url:!:http://]!] "[![Title]!]")', placeHolder:'Your text to link here...' },
            {name:'Link', key:"L", openWith:'[', closeWith:']([![Url:!:http://]!])', placeHolder:'Your text to link here...' },
            //{separator:'---------------'},
            {name:'Quotes', openWith:'> '},
            {name:'Code Block / Code', openWith:'(!(\t|!|`)!)', closeWith:'(!(`)!)'}
        ]
    }

    /*
     * Textile
     */
    configuration['textile'] = {
        nameSpace:           "textile", // Useful to prevent multi-instances CSS conflict
        onShiftEnter:        {keepDefault:false, replaceWith:'\n\n'},
        markupSet: [
            {name:'Heading 1', key:'1', openWith:'h1(!(([![Class]!]))!). ', placeHolder:'Your title here...' },
            {name:'Heading 2', key:'2', openWith:'h2(!(([![Class]!]))!). ', placeHolder:'Your title here...' },
            {name:'Heading 3', key:'3', openWith:'h3(!(([![Class]!]))!). ', placeHolder:'Your title here...' },
            {name:'Heading 4', key:'4', openWith:'h4(!(([![Class]!]))!). ', placeHolder:'Your title here...' },
            {name:'Heading 5', key:'5', openWith:'h5(!(([![Class]!]))!). ', placeHolder:'Your title here...' },
            {name:'Heading 6', key:'6', openWith:'h6(!(([![Class]!]))!). ', placeHolder:'Your title here...' },
            {name:'Paragraph', key:'P', openWith:'p(!(([![Class]!]))!). '},
            {separator:'---------------' },
            {name:'Bold', key:'B', closeWith:'*', openWith:'*'},
            {name:'Italic', key:'I', closeWith:'_', openWith:'_'},
            {name:'Stroke through', key:'S', closeWith:'-', openWith:'-'},
            {separator:'---------------' },
            {name:'Bulleted list', openWith:'(!(* |!|*)!)'},
            {name:'Numeric list', openWith:'(!(# |!|#)!)'},
            {separator:'---------------' },
            {name:'Picture', replaceWith:'![![Source:!:http://]!]([![Alternative text]!])!'},
            {name:'Link', openWith:'"', closeWith:'([![Title]!])":[![Link:!:http://]!]', placeHolder:'Your text to link here...' },
            {separator:'---------------' },
            {name:'Quotes', openWith:'bq(!(([![Class]!]))!). '},
            {name:'Code', openWith:'@', closeWith:'@'}
        ]
    }

    return configuration[language];
  }
}

jsb.registerHandler('markup_field', MarkupFieldBehaviour);
