import jsb from 'node-jsb'

(function () {

    'use strict';

    var Anker = function (dom_element, options)
    {
        var that = this;
        options = options || {};

        that.options = {};
        $.extend(true, that.options, options);

        // DOM elements
        that.dom_element = $(dom_element);
        that.field_name = that.dom_element.find('.js-field-name');
        that.field_anker = that.dom_element.find('.js-field-anker');
        that.slug = that.dom_element.find('.js-generated-slug');

        that.initialize();
    };

    Anker.prototype.initialize = function (value)
    {
      var that = this;

      that.slug_present = (that.field_anker.val() != '')
      that.update_slug(that.field_name.val());

      that.field_name.on('keyup', function (event) {
        that.update_slug(event.target.value);
      });
    };

    Anker.prototype.update_slug = function (value)
    {
      var slug = this.generated_slug(value);
      this.field_anker.val(slug);

      var retval = slug;

      if(!this.slug_present)
      {
        this.slug.css({fontStyle: 'italic'})
      }

      this.slug.text('#' + retval);
    };

    Anker.prototype.generated_slug = function (value)
    {
      var str = value.toLowerCase().trim();

      var from = ['ä', 'ö', 'ü', 'ß', '&'];
      var to   = ['ae', 'oe', 'ue', 'ss', 'und'];

      for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from[i], 'g'), to[i]);
      }

      return str.normalize('NFD') // separate accent from letter
    	.replace(/[\u0300-\u036f]/g, '')  // remove all separated accents
    	.replace(/\s+/g, '-')             // replace spaces with -
    	.replace(/[^\w\-]+/g, '')         // remove all non-word chars
    	.replace(/\-\-+/g, '-')           // replace multiple '-' with single '-'
    };

    jsb.registerHandler("Anker", Anker);

})();
